import {isNullOrEmpty, isNumber} from "./utils/utilities";

export function SubscriptionCalculator(subscription, freeSms) {
    Subscription.call(this, subscription);
    this.freeSms = !!freeSms;
    // if (!hasOwnProperty(this.mastersCount))
    if (!this.mastersCount)
        this.mastersCount = this.defaultMastersCount;
    // Find maximum discount
    var maxDiscount = 0;
    if (this.discounts.length !== 0) {
        this.discounts.forEach(function (discount) {
            if (discount.discount > maxDiscount)
                maxDiscount = discount.discount;
        })
    }
    this.subscriptionDiscount = maxDiscount;
    this.setPeriods();
}

SubscriptionCalculator.prototype = Object.create(Subscription.prototype);

SubscriptionCalculator.prototype.setPeriods = function () {
    this.PERIOD = {
        MONTH: {
            months: 1,
            monthsToPay: this.monthMonthsToPay,
            label: 'МЕСЯЦ',
            discount: this.getDiscount(this.subscriptionDiscount + this.monthDiscount),
            // discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.monthDiscount), 1)
            discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.monthDiscount), 0)
        },
        QUARTER: {
            months: 3,
            monthsToPay: this.quarterMonthsToPay,
            label: 'КВАРТАЛ',
            discount: this.getDiscount(this.subscriptionDiscount + this.quarterDiscount),
            // discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.quarterDiscount), 3)
            discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.quarterDiscount), 0)
        },
        HALF_YEAR: {
            months: 6,
            monthsToPay: this.halfYearMonthsToPay,
            label: 'ПОЛГОДА',
            discount: this.getDiscount(this.subscriptionDiscount + this.halfYearDiscount),
            // discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.halfYearDiscount), 6)
            discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.halfYearDiscount), 0)
        },
        YEAR: {
            months: 12,
            monthsToPay: this.yearMonthsToPay,
            label: 'ГОД',
            discount: this.getDiscount(this.subscriptionDiscount + this.yearDiscount),
            // discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.yearDiscount), 6)
            discountText: this.getDiscountText(this.getDiscount(this.subscriptionDiscount + this.yearDiscount), 0)
        }
    };
};

SubscriptionCalculator.prototype.getDiscount = function (discount) {
    return Math.min(isNumber(discount) ? discount : 0, 100);
};

SubscriptionCalculator.prototype.getDiscountText = function (discount, freeSmsMonths) {
    var discountText = discount > 0 ? 'Скидка ' + discount + '%' : '';
    if (this.freeSms && freeSmsMonths > 0) {
        if (!isNullOrEmpty(discountText))
            discountText += " + ";
        discountText += freeSmsMonths + ' мес. бесплатных смс'
    }
    return discountText;
};

SubscriptionCalculator.prototype.fixedMastersCount = function () {
    return this.minMastersCount === this.maxMastersCount;
};

SubscriptionCalculator.prototype.getOldPrice = function () {
    return this.oldMonthPrice * this.period.months;
};

SubscriptionCalculator.prototype.getNewPrice = function () {
    return this.newMonthPrice * this.period.monthsToPay;
};

SubscriptionCalculator.prototype.isMinMastersCount = function () {
    return this.mastersCount === this.minMastersCount;
};

SubscriptionCalculator.prototype.isMaxMastersCount = function () {
    return this.mastersCount === this.maxMastersCount;
};

SubscriptionCalculator.prototype.calcMonthPrices = function () {
    var oldPrice = 0.0;
    var mastersCount = this.mastersCount;
    this.prices.forEach(function (price) {
        if (mastersCount >= price.fromOrdinal) {
            var till = !isNullOrEmpty(price.toOrdinal)
                ? Math.min(mastersCount, price.toOrdinal)
                : mastersCount;
            var count = till - price.fromOrdinal + 1;
            oldPrice += count * price.price;
        }
    });
    this.oldMonthPrice = oldPrice;
    this.discountAmount = this.oldMonthPrice * this.period.discount / 100.0;
    this.newMonthPrice = this.oldMonthPrice - this.discountAmount;
};

SubscriptionCalculator.prototype.setMonthsCount = function (monthsCount) {
    switch (monthsCount) {
        case 1:
            this.period = this.PERIOD.MONTH;
            break;
        case 3:
            this.period = this.PERIOD.QUARTER;
            break;
        case 6:
            this.period = this.PERIOD.HALF_YEAR;
            break;
        case 12:
            this.period = this.PERIOD.YEAR;
            break;
    }
    this.calcMonthPrices();
};

SubscriptionCalculator.prototype.getMonthsLabel = function () {
    return this.period.label;
};


///////////////////////////////////////////////////////////////////////////
// SUBSCRIPTION
///////////////////////////////////////////////////////////////////////////

function Subscription (subscription) {
    this.isSalonSubscription = subscription.subscriptionId != null;
    this.id = this.isSalonSubscription ? subscription.subscriptionId : subscription.id;
    this.salonSubscriptionId = this.isSalonSubscription ? subscription.id : null;
    this.name = subscription.name;
    this.ordinal = subscription.ordinal;
    this.mastersCount = subscription.mastersCount;
    this.monthsCount = subscription.monthsCount;
    this.minMastersCount = subscription.minMastersCount;
    this.maxMastersCount = subscription.maxMastersCount;
    this.defaultMastersCount = subscription.defaultMastersCount;
    this.monthAllowed = subscription.monthAllowed;
    this.monthMonthsToPay = subscription.monthMonthsToPay;
    this.monthDiscount = subscription.monthDiscount;
    this.quarterAllowed = subscription.quarterAllowed;
    this.quarterMonthsToPay = subscription.quarterMonthsToPay;
    this.quarterDiscount = subscription.quarterDiscount;
    // Set half year not allowed
    //this.halfYearAllowed = subscription.halfYearAllowed;
    this.halfYearAllowed = false;
    this.halfYearMonthsToPay = subscription.halfYearMonthsToPay;
    this.halfYearDiscount = subscription.halfYearDiscount;
    this.yearAllowed = subscription.yearAllowed;
    this.yearMonthsToPay = subscription.yearMonthsToPay;
    this.yearDiscount = subscription.yearDiscount;
    this.trial = subscription.trial;
    this.active = subscription.active;
    this.prices = subscription.prices ? subscription.prices.map(function (price) { return new SubscriptionPrice(price) }) : [];
    this.discounts = subscription.discounts ? subscription.discounts.map(function (discount) { return new SubscriptionDiscount(discount) }) : [];
}

///////////////////////////////////////////////////////////////////////////
// SUBSCRIPTION PRICE
///////////////////////////////////////////////////////////////////////////

function SubscriptionPrice (price) {
    this.id = price.id;
    this.subscriptionId = price.subscriptionId;
    this.startDate = price.startDate;
    this.endDate = price.endDate;
    this.fromOrdinal = price.fromOrdinal;
    this.toOrdinal = price.toOrdinal;
    this.price = price.price;
}

///////////////////////////////////////////////////////////////////////////
// SUBSCRIPTION DISCOUNT
///////////////////////////////////////////////////////////////////////////

function SubscriptionDiscount (discount) {
    this.id = discount.id;
    this.subscriptionId = discount.subscriptionId;
    this.startDate = discount.startDate;
    this.endDate = discount.endDate;
    this.discount = discount.discount;
}
