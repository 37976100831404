import {Sidebar} from "./components/sidebar";
import {ErrorModal} from "./components/error-modal";
import {PricesGrid} from "./prices-grid";
import {setVisibility, showToast} from "./utils/utilities";
import {Form} from "./components/form";
import {ajaxGet, ajaxPostJSON} from "./utils/api-utilities";
import {BASE_URL} from "./settings";

const $ = require('jquery');
// import 'jquery'
// import 'slick-carousel'

var landingPage;

var menuSidebar;

var countryCode = "KZ";
var apiId = null;

var confirmationModal, errorModal;

$(document).ready(function() {
    // create sidebar and attach to menu open

    menuSidebar = new Sidebar($('.ui.sidebar'));
    menuSidebar.setToggler('.toc.item');

    // $('.ui.sidebar').sidebar('attach events', '.toc.item');

    // Scroll to top button
    // $('body').append('<a href="#" id="go-top" title="Вверх"><img src="/index-resources/images/up-arrow.png" alt=""></a>');
    $('body').append('<a href="#" id="go-top" title="Вверх"></a>');
    // Error modal
    errorModal = new ErrorModal($('#error-modal'));
    errorModal.setClosable(false);
    // Index page
    landingPage = new LandingPage();
    // // Gift image
    // var giftImg = $('.gift-img');
    // giftImg.visibility({
    //     once: false,
    //     // update size when new content loads
    //     observeChanges: true,
    //     // load content on bottom edge visible
    //     onBottomVisible: function() {
    //         // giftImg.transition('tada');
    //         giftImg.transition('jiggle');
    //     }
    // });
});

$(function() {
    $.fn.scrollToTop = function() {
        $(this).hide().removeAttr("href");
        if ($(window).scrollTop() >= "550") $(this).fadeIn("slow");
        var scrollDiv = $(this);
        $(window).scroll(function() {
            if ($(window).scrollTop() <= "550") $(scrollDiv).fadeOut("slow");
            else $(scrollDiv).fadeIn("slow")
        });
        $(this).click(function() {
            $("html, body").animate({scrollTop: 0}, "slow")
        })
    }
});

$(function() {
    $("#go-top").scrollToTop();
});



///////////////////////////////////////////////////////////////////////////
// LANDING PAGE
///////////////////////////////////////////////////////////////////////////

var subscriptions = "[{\"id\":2565,\"name\":\"Мастер\",\"ordinal\":10,\"minMastersCount\":1,\"maxMastersCount\":1,\"defaultMastersCount\":1,\"monthAllowed\":true,\"quarterAllowed\":false,\"halfYearAllowed\":true,\"yearAllowed\":true,\"trial\":false,\"active\":true,\"prices\":[{\"id\":2566,\"subscriptionId\":2565,\"startDate\":1549843200000,\"endDate\":null,\"fromOrdinal\":1,\"toOrdinal\":1,\"price\":2000.0}],\"discounts\":[{\"id\":2567,\"subscriptionId\":2565,\"startDate\":1549843200000,\"endDate\":1553990400000,\"discount\":50.0}]},{\"id\":2568,\"name\":\"Старт\",\"ordinal\":20,\"minMastersCount\":5,\"maxMastersCount\":5,\"defaultMastersCount\":5,\"monthAllowed\":true,\"quarterAllowed\":false,\"halfYearAllowed\":true,\"yearAllowed\":true,\"trial\":false,\"active\":true,\"prices\":[{\"id\":2569,\"subscriptionId\":2568,\"startDate\":1549843200000,\"endDate\":null,\"fromOrdinal\":1,\"toOrdinal\":5,\"price\":1000.0}],\"discounts\":[{\"id\":2570,\"subscriptionId\":2568,\"startDate\":1549843200000,\"endDate\":1553990400000,\"discount\":50.0}]},{\"id\":2571,\"name\":\"Рост\",\"ordinal\":30,\"minMastersCount\":6,\"maxMastersCount\":null,\"defaultMastersCount\":10,\"monthAllowed\":true,\"quarterAllowed\":false,\"halfYearAllowed\":true,\"yearAllowed\":true,\"trial\":false,\"active\":true,\"prices\":[{\"id\":2572,\"subscriptionId\":2571,\"startDate\":1549843200000,\"endDate\":null,\"fromOrdinal\":1,\"toOrdinal\":20,\"price\":1000.0},{\"id\":2573,\"subscriptionId\":2571,\"startDate\":1549843200000,\"endDate\":null,\"fromOrdinal\":21,\"toOrdinal\":null,\"price\":500.0}],\"discounts\":[{\"id\":2574,\"subscriptionId\":2571,\"startDate\":1549843200000,\"endDate\":1553990400000,\"discount\":50.0}]}]";

function LandingPage() {
    this.setupNavigation();
    ajaxGet(
      BASE_URL + '/v2/public/subscriptions',
      {}, false, false,
        data => {
          this.pricesGrid = new PricesGrid(true, data, true, false);
      }
    );
    // this.pricesGrid = new PricesGrid(true, JSON.parse(getInputParameter($('#subscriptions-par'))), true, false);
    this.bindUI();

    // this.pricesGrid = new PricesGrid(JSON.parse(subscriptions));
    // this.initApplicationModal();
    this.initApplicationForm();
}



LandingPage.prototype.setupNavigation = function () {
    var that = this;
    // Click listeners on menu items
    $('a.nav').click(function() {
        // Возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
        that.scrollToElement($($(this).attr('href')));
        // Закрываем sidebar
        if (menuSidebar.isVisible())
            menuSidebar.hide();
        // Выключаем стандартное действие
        return false;
    });
};


LandingPage.prototype.scrollToElement = function (el) {
    // Проверим существование элемента чтобы избежать ошибки
    if (el.exists()) {
        // Анимируем скроолинг к элементу scroll_el
        $('html, body').animate({ scrollTop: el.offset().top }, 1500);
        // var navTrigger = $('.nav-trigger');
        // if (navTrigger.hasClass('check')) {
        //     navTrigger.removeClass('check');
        //     $('.site-wrap').css("left","0px");
        // }
    }
};

LandingPage.prototype.bindUI = function () {
    var that = this;
    // Main video
    $('#main-video').embed();
    // Promo video
    $('#promo-video').embed();
    // Try timebot btn
    $('.try-beauty-bot-btn:not(#apply-btn)').on('click', function () {
        //// that.scrollToElement($('#contacts-section'));
        //that.scrollToElement($('.request-form-container'));
        //// showToast("Заполните заявку на подключение", "Пробный период", "#0071bc", 7, "edit outline");
        //showToast("Заполните заявку на подключение", "Пробный период", "#FF5964", 7, "edit outline");
        //// that.applicationModal.showApplicationModal();

        window.open("/app/salon#/registration");
    });
    // Init slick for feedback cards
    //require('jquery')
    var feedbacksContainer = $('.feedbacks-container');
    feedbacksContainer.slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false
                }
            }
        ]
    });
    // Init feedback cards
    this.initFeedbackCards();
    // On before slide change
    feedbacksContainer.on('breakpoint', function(event, slick, breakpoint){
        // Re init feedback cards
        that.initFeedbackCards();
    });

};

LandingPage.prototype.initFeedbackCards = function() {
    // Feedback cards
    var feedbackCards = $('.feedback.card');
    feedbackCards.each(function (index) {
        var card = $(feedbackCards[index]);
        var readMoreContent = card.find('.read-more.content');
        var readMoreLink = card.find('.read-more-link');
        var collapseContent = card.find('.collapse.content');
        var collapseLink = card.find('.collapse-link');
        var coverImage = card.find('.cover.image');
        var feedbackText = card.find('.feedback-text');
        var feedbackShortText = card.find('.feedback-short-text');
        readMoreLink.on('click', function () {
            setVisibility(readMoreLink, false, true);
            coverImage.transition({
                animation: 'swing up',
                duration   : '1s',
                onComplete : function() {
                    setVisibility(readMoreContent, false);
                    setVisibility(feedbackShortText, false);
                    setVisibility(feedbackText, true);
                    setVisibility(collapseContent, true);
                    setVisibility(collapseLink, true, true);
                }
            });
        });

        collapseLink.on('click', function () {
            setVisibility(collapseLink, false, true);
            setVisibility(collapseContent, false);
            setVisibility(feedbackText, false);
            setVisibility(feedbackShortText, true);
            setVisibility(readMoreContent, true);
            coverImage.transition({
                animation: 'swing up',
                duration   : '1s',
                onComplete : function() {
                    setVisibility(readMoreLink, true, true);
                }
            });
        });
    });
};


// LandingPage.prototype.initApplicationModal = function () {
//     var that = this;
//     // // Init modals
//     // this.applicationModal = new ApplicationModal();
//     // $('.show-application-modal').on('click', function () {
//     //     that.applicationModal.showApplicationModal();
//     // });
// };
//
LandingPage.prototype.initApplicationForm = function () {
    var that = this;
    this.form = new Form($('#application-form'));
    // Name
    this.form.name = this.form.addField('name');
    this.form.name.setMaxLengthError(100, "Длина имени не должно превышать 100 символов");
    // Phone number
    this.form.phoneNumber = this.form.addField('phone');
    this.form.phoneNumber.initPhoneInput(null);
    this.form.phoneNumber.setMaxLengthError(15, "Длина телефона не должна превышать 15 символов");
    this.form.phoneNumber.setEmptyError('Необходимо указать номер телефона');
    this.form.initForm();
    // Apply button
    this.applyBtn = $('#apply-btn');
    this.applyBtn.keypress(function(e) {
        if (e.which === 13)
            postApplication(that.form)
    });
    this.applyBtn.on('click', function () {
        postApplication(that.form);
    });
};

function postApplication(form, beforePostFn) {
    var that = this;
    if (form.validateForm()) {
        var applicationData = form.getFieldValues();
        console.log('Before posting application:');
        console.log(applicationData);
        if (beforePostFn)
            beforePostFn();
        ajaxPostJSON(
          BASE_URL + '/v2/public/site-applications/register',
          applicationData,
          true,
          true,
          () => {
                // showToast("Ваша заявка принята в обработку", "Заявка отправлена", "#0071bc", 7);
                showToast("Ваша заявка принята в обработку", "Благодарим Вас!", "#21BA45", 7);
                form.reset();
                if (form.phoneNumber)
                    form.phoneNumber.clearPhoneHint();
          }
        );
    }
}

export function showErrorModal(text) {
    errorModal.setText(text);
    errorModal.show();
}
