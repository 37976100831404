import {Field} from "./field";

export function Form(form) {
    this.form = form;
    this.parameters = {
        inline: true,
        keyboardShortcuts: false,
        fields: {}
    };
    this.fields = [];
    this.initialValues = {};
}

///////////////////////////////////////////////////////////////////////////
// INITIALIZATION
///////////////////////////////////////////////////////////////////////////

Form.prototype.initForm = function () {
    for (var i = 0; i < this.fields.length; i++) {
        var field = this.fields[i];
        // Add field to parameters
        this.parameters.fields[field.name] = {
            identifier: field.name,
            rules: field.rules
        };
        // Add initial field value
        if (field.initialValue) {
            this.initialValues[field.name] = field.initialValue;
            field.prevValue = field.initialValue;
        }
    }
    this.form.form(this.parameters);
    this.form.form('set values', this.initialValues)
};

///////////////////////////////////////////////////////////////////////////
// PARAMETERS
///////////////////////////////////////////////////////////////////////////

Form.prototype.setInline = function (inline) {
    this.parameters.inline = inline;
};

Form.prototype.setOn = function (on) {
    this.parameters.on = on;
};

Form.prototype.addField = function (name) {
    var field = new Field(null, {
        form: this,
        fieldName: name
    });
    this.fields.push(field);
    // Attack enter pressed callback
    if (this.onEnterPressed) {
        var that = this;
        field.field.keypress(function (event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode === '13') {
                that.onEnterPressed();
            }
        });
    }
    return field;
};

Form.prototype.setEnterPressed = function (onEnterPressed) {
    this.onEnterPressed = onEnterPressed;
};

///////////////////////////////////////////////////////////////////////////
// MANIPULATIONS
///////////////////////////////////////////////////////////////////////////

Form.prototype.clearErrors = function () {
    this.form.removeClass("error");
    this.form.find('.field.error').removeClass( "error" );
    // $('.ui.form .field.error').removeClass( "error" );
    // $('.ui.form.error').removeClass( "error" );
};

Form.prototype.validateForm = function () {
    this.form.form('validate form');
    return this.form.form('is valid');
};

Form.prototype.isValid = function () {
    return this.form.form('is valid');
};

// Returns all form values to their default value. This is the value the form fields were set to when the page loaded.
Form.prototype.reset = function () {
    return this.form.form('reset');
};

// Removes all values from form fields and reset dropdowns to placeholder text
Form.prototype.clear = function () {
    return this.form.form('clear');
};

Form.prototype.setFieldValue = function (fieldName, value) {
    this.form.form('set value', fieldName, value);
};

Form.prototype.setFieldValues = function (values) {
    this.form.form('set values', values);
};

Form.prototype.getFieldValue = function (fieldName) {
    return this.form.form('get value', fieldName);
};

Form.prototype.getFieldValues = function () {
    var values = {};
    this.fields.forEach(function (field) {
        values[field.name] = field.getValue();
    });
    return values
    // return this.form.form('get values');
};

