import $ from 'jquery';
import {hasAttribute, isNullOrEmpty, parseFloatOrZero, parseIntOrZero, parsePhone} from "../utils/utilities";
import {fieldTypes} from "./objects";
import {COUNTRY_CODE} from "../settings";

export function Field(field, parameters) {
    if (field)
        this.field = field;
    if (parameters) {
        if (parameters.form) {
            this.form = parameters.form;
            this.name = parameters.fieldName;
            this.field = parameters.form.form.find('[name=' + this.name + ']');
        }
    }
    this.rules = [];
    this.type = null;
    this.checkInitialValue();
}

Field.prototype.checkInitialValue = function () {
    this.initialValue = '';
    var initialValue = this.field.attr('val');
    if (!isNullOrEmpty(initialValue)) {
        this.initialValue = initialValue;
        this.prevValue = initialValue;
        if (this.form == null)
            this.setValue(this.initialValue);
    }
};

///////////////////////////////////////////////////////////////////////////
// PARAMETERS
///////////////////////////////////////////////////////////////////////////

Field.prototype.setEmptyError = function(text) {
    this.rules.push({
        type   : 'empty',
        prompt : text
    })
};

Field.prototype.setInvalidPhoneNumberError = function(text) {
    this.rules.push({
        type   : 'phone',
        prompt : text
    })
};

Field.prototype.setNotInRangeError = function (min, max, text) {
    this.rules.push({
        type   : 'integer[' + min + '..' + max + ']',
        prompt : text
    })
};

Field.prototype.setInvalidEmailError = function(text) {
    this.rules.push({
        type   : 'email',
        prompt : text
    })
};

Field.prototype.setLengthError = function (length, text) {
    this.rules.push({
        type   : 'length[' + length + ']',
        prompt : text
    })
};

Field.prototype.setMaxLengthError = function (length, text) {
    this.rules.push({
        type   : 'maxLength[' + length + ']',
        prompt : text
    })
};

Field.prototype.setNotMatchError = function (fieldName, text) {
    this.rules.push({
        type   : 'match[' + fieldName + ']',
        prompt : text
    })
};

Field.prototype.setCustomError = function(name, text, isValidFn) {
    if (isValidFn) {
        $.fn.form.settings.rules[name] = function (value) {
            return isValidFn(value);
        };
        this.rules.push({
            type: name,
            prompt: text
        });
    }
};

Field.prototype.initDigitsField = function (length, onChanged) {
    if (this.field.exists()) {
        this.type = fieldTypes.digitsField;
        this.onChange = onChanged;
        var parameters = {
            numericOnly: true,
            value: this.initialValue
        };
        if (length)
            parameters.blocks = [length];
        if (onChanged) {
            parameters.onValueChanged = function (e) {
                var newValue = e.target.rawValue;
                newValue = newValue && newValue.length !== 0 ? parseIntOrZero(newValue) : 0;
                onChanged(newValue);
            }
        }
        this.cleave = new Cleave(this.field, parameters);
    }
};

Field.prototype.initQuantityField = function (onChanged) {
    if (this.field.exists()) {
        var that = this;
        this.type = fieldTypes.quantityField;
        this.onChange = onChanged;
        var parameters = {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalScale: 0,
            delimiter: ' ',
            numeralPositiveOnly: true,
            value: this.initialValue
        };
        if (onChanged) {
            parameters.onValueChanged = function (e) {
                var prevValue = that.prevValue;
                var newValue = e.target.rawValue;
                newValue = newValue && newValue.length !== 0 ? parseIntOrZero(newValue) : 0;
                onChanged(newValue, prevValue);
            }
        }
        this.cleave = new Cleave(this.field, parameters);
    }
};

Field.prototype.initAmountField = function (onChanged) {
    if (this.field.exists()) {
        var that = this;
        this.type = fieldTypes.amountField;
        this.onChange = onChanged;
        var parameters = {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalScale: 2,
            numeralDecimalMark: ',',
            delimiter: ' ',
            numeralPositiveOnly: true,
            value: this.initialValue
        };
        if (onChanged) {
            parameters.onValueChanged = function (e) {
                var prevValue = that.prevValue;
                var newValue = e.target.rawValue;
                newValue = newValue && newValue.length !== 0 ? parseFloatOrZero(newValue) : 0.0;
                that.prevValue = newValue;
                onChanged(newValue, prevValue);
            }
        }
        this.cleave = new Cleave(this.field, parameters);
        this.prevValue = this.getValue();
    }

};

Field.prototype.initPhoneInputOld = function (onChanged) {
    if (this.field.exists()) {
        this.type = fieldTypes.phoneField;
        this.imask = new IMask(this.field[0], {
            mask: '+{7} (000) 000-00-00'
        });
        // this.onChange = onChanged;
        // var parameters = {
        //     phone: true,
        //     phoneRegionCode: countryCode
        // };
        //
        // this.cleave = new Cleave(this.field, parameters);
    }
};

import AsYouType from 'libphonenumber-js'


Field.prototype.initPhoneInput = function (absoluteHint, hintText, invalidPhoneNumberError, onPhoneHintChanged) {
    if (this.field.exists()) {
        var that = this;
        this.type = fieldTypes.phoneField;
        this.setInvalidPhoneNumberError(isNullOrEmpty(invalidPhoneNumberError) ? 'Некорректный номер телефона' : invalidPhoneNumberError);
        if (!hintText)
            hintText = 'Номер:';
        this.phoneHint = absoluteHint === true ? $('<span class="absolute-phone-hint">') : absoluteHint === false ? $('<span class="phone-hint">') : null;
        if (this.phoneHint)
            this.phoneHint.insertAfter(this.field);
        this.field.keyup(function(event) {
            // Prevent formatting on selection
            var selection = window.getSelection().toString();
            if ( selection !== '')
                return;
            // Prevent formatting on arrow keys and delete key (46) and backspace (8)
            // log(event.key);
            // log(event.keyCode);
            if ($.inArray(event.keyCode, [38, 40, 37, 39]) !== -1)
                return;
            // var hint = new libphonenumber.AsYouType(countryCode).input($(this).val());
            //var hint = new AsYouType(COUNTRY_CODE).input($(this).val());
            //log(hint);
            //if (that.phoneHint)
            //    that.phoneHint.html(isNullOrEmpty(hint) ? '' : hintText + ' ' + hint);
            if (onPhoneHintChanged)
                onPhoneHintChanged(hint);

            // Retrieve value
            // var $this = $(this);
            // var value = $this.val();
            // value = new libphonenumber.AsYouType(countryCode).input(value);
            // log(value);
            // $this.val(value);
        });
    }
};

Field.prototype.clearPhoneHint = function () {
    if (this.phoneHint && this.phoneHint.exists())
        this.phoneHint.html('');
};

Field.prototype.initNumberRangeInput = function (min, max) {
    if (this.field.exists()) {
        this.type = fieldTypes.numberRange;
        this.imask = new IMask(this.field[0], {
            mask: Number,
            min: min,
            max: max,
            thousandsSeparator: ' '
        });
    }
};

Field.prototype.initTextField = function (onTextChanged, onEnterPressed) {
    if (this.field.exists() && onTextChanged) {
        this.field.keyup(function (event) {
            // Prevent formatting on selection
            var selection = window.getSelection().toString();
            if (selection !== '')
                return;
            if (!isNullOrEmpty(onEnterPressed) && event.which === 13)
                onEnterPressed();
            // Prevent formatting on arrow keys and delete key (46) and backspace (8)
            if ($.inArray(event.keyCode, [38, 40, 37, 39]) !== -1)
                return;
            onTextChanged($(this).val());
        });
    }
};

///////////////////////////////////////////////////////////////////////////
// MANIPULATIONS
///////////////////////////////////////////////////////////////////////////

Field.prototype.setValue = function (value, silent) {
    switch (this.type) {
        case fieldTypes.digitsField:
            this.cleave.setRawValue(value);
            break;
        case fieldTypes.quantityField:
            this.cleave.setRawValue(value);
            break;
        case fieldTypes.amountField:
            this.cleave.setRawValue(value);
            break;
        default:
            if (this.form)
                this.form.setFieldValue(this.name, value);
            else
                this.field.val(value);
    }
    if (this.onChange && silent !== true)
        this.onChange(value);
    this.clearPhoneHint();
};

Field.prototype.getValue = function () {
    switch (this.type) {
        case fieldTypes.quantityField:
            return parseIntOrZero(this.cleave.getRawValue());
        case fieldTypes.amountField:
            return parseFloatOrZero(this.cleave.getRawValue());
        case fieldTypes.phoneField:
            if (!hasAttribute(this.field, 'e164')) {
                log('No e164 attribute');
                // var phoneNumber = parsePhone(value);
                var phoneNumber = parsePhone(this.form.getFieldValue(this.name));
                return phoneNumber && phoneNumber.isValid() ? phoneNumber.number : null;
            } else return this.field.attr('e164');
        default:
            return this.form ? this.form.getFieldValue(this.name) : this.field.val()
    }
};

Field.prototype.setDisabled = function (disabled) {
    this.field.attr('disabled', disabled === true ? '' : null);
};

Field.prototype.validateField = function () {
    if (this.form) {
        this.form.form.form('validate field', this.name);
        return this.form.form.form('is valid', this.name);
    }
    return true;

};

Field.prototype.isValid = function () {
    return this.form.form.form('is valid');
};

///////////////////////////////////////////////////////////////////////////
// CALLBACKS
///////////////////////////////////////////////////////////////////////////

Field.prototype.setFocusOutCallback = function (callback) {
    if (!callback) return;
    if (this.field) {
        var that = this;
        this.prevValue = this.getValue();
        this.field.focusout(function () {
            var newValue = that.getValue();
            if (callback(that.prevValue, newValue, that) === false) {
                that.setValue(that.prevValue);
            } else that.prevValue = newValue;
        });
    }
};

Field.prototype.setChangeOnBlurCallback = function (callback) {
    if (!callback) return;
    if (this.field) {
        var that = this;
        this.prevValue = this.getValue();
        this.field.focusout(function () {
            var newValue = that.getValue();
            if (that.prevValue !== newValue) {
                if (callback(newValue, that.prevValue, that) === false) {
                    that.setValue(that.prevValue);
                } else that.prevValue = newValue;
            }
        });
        // Trigger blur on enter pressed
        this.field.keypress(function (e) {
            if (e.which === 13) {
                that.field.blur();
                return false;
            }
        });
    }
};

///////////////////////////////////////////////////////////////////////////
// CUSTOM VALIDATION RULES
///////////////////////////////////////////////////////////////////////////



$.fn.form.settings.rules.phone = function(value, region) {
    var $this = $(this);
    $this.attr('e164', '');
    if (isNullOrEmpty(value))
        return true;
    // log(region);
    // if (isNullOrEmpty(region))
    //     region = countryCode;
    // var libphonenumber = require(libphonenumber)
    var phoneNumber = parsePhone(value);
    if (phoneNumber && phoneNumber.isValid()) {
        $this.attr('e164', phoneNumber.number);
        $this.val(phoneNumber.formatInternational());
        return true;
    }
    return false;
};

