
function copyArray(array) {
    return array.slice();
}

function sortBy(array, getValue) {
    array.sort(getComparator(getValue));
    return array;
}


function getComparator(getValue) {
    return function compare(first, second) {
        var firstVal = getValue(first);
        var secondVal = getValue(second);
        if (firstVal < secondVal)
            return -1;
        if (firstVal > secondVal)
            return 1;
        return 0;
    }
}

var groupByKey = function(array, key) {
    return array.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

var groupBy = function(array, byFn) {
    return array.reduce(function(rv, x) {
        (rv[byFn(x)] = rv[byFn(x)] || []).push(x);
        return rv;
    }, {});
};

export function isArray(variable) {
    return variable && variable.constructor === Array;
}

function removeFromArray(array, element) {
    var index = array.indexOf(element);
    if (index > -1)
        array.splice(index, 1);
}

function getSeparatedText(array, separator, getTextFn) {
    var text = '';
    array.forEach(function (item) {
        if (text !== '')
            text += separator;
        if (!getTextFn)
            text += item;
        else text += getTextFn(item);
    });
    return text;
}

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

function arraysSameContent(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    a.sort();
    b.sort();
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}
