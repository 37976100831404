import $ from 'jquery';
import {addStyleAttribute} from "../utils/utilities";

export function Modal(modal) {
    this.modal = modal;
    this.setAutoFocus(false);
    var that = this;
    this.modal.modal('setting', 'onVisible', function () {
        that.onBecomeVisible();
    });
}

Modal.prototype.setClosable = function (closable) {
    this.modal.modal('setting', 'closable', closable);
};

Modal.prototype.setAutoFocus = function (autofocus) {
    this.modal.modal('setting', 'autofocus', autofocus);
};

Modal.prototype.setUseFlex = function (useFlex) {
    this.modal.modal('setting', 'useFlex', useFlex);
};

Modal.prototype.setOnVisible = function (onVisible) {
    this.onVisible = onVisible;
};

Modal.prototype.show = function () {
    this.modal.modal('show');
    // this.modal.css('display', 'flex');
    $('.modal-popup-trigger').popup();
};

Modal.prototype.hide = function () {
    this.modal.modal('hide');
};

///////////////////////////////////////////////////////////////////////////
// PRIVATE
///////////////////////////////////////////////////////////////////////////

Modal.prototype.onBecomeVisible = function () {
    addStyleAttribute(this.modal,
        'display: -webkit-box !important;' +
        '    display: -ms-flexbox !important;' +
        '    display: flex !important;'
    );
    if (this.onVisible)
        this.onVisible();
};


