import {mergeObjects} from "./object-utils";
import {blockUi, isNullOrEmpty, log, logWithHeader, unblockUi} from "./utilities";
import {API_ID, API_ID_REQUEST_HEADER} from "../settings";
import {showErrorModal} from "../landing";

const $ = require('jquery');

export function ajaxGet(url, data, async, blockUI, onSuccess, onError) {
    return $.ajax(url, mergeObjects(getRequestOptions(async, blockUI, onSuccess, onError), {
        type: 'GET',
        data: data
    }));
}

function ajaxPost(url, data, async, blockUI, onSuccess, onError) {
    return $.ajax(url, mergeObjects(getRequestOptions(async, blockUI, onSuccess, onError), {
        type: 'POST',
        data: data
    }));
}

export function ajaxPostJSON(url, data, async, blockUI, onSuccess, onError) {
    return $.ajax(url, mergeObjects(getRequestOptions(async, blockUI, onSuccess, onError), {
        type: 'POST',
        contentType : 'application/json; charset=utf-8',
        dataType : 'json',
        data: JSON.stringify(data)
    }));
}

function ajaxPostFile(url, data, async, blockUI, onSuccess, onError) {
    return $.ajax(url, mergeObjects(getRequestOptions(async, blockUI, onSuccess, onError), {
        type: 'POST',
        enctype: 'multipart/form-data',
        data: data,
        processData: false,
        contentType: false
    }));
}

function getRequestOptions(async, blockUI, onSuccess, onError) {
    async = async !== false;
    return {
        async: async,
        beforeSend: function (xhr, settings) {
            if (blockUI === true)
                blockUi();
            setXSRFHeader(xhr, settings);
            if (!isNullOrEmpty(API_ID))
                xhr.setRequestHeader(API_ID_REQUEST_HEADER, API_ID);
        },
        success: function (response, textStatus, xhr) {
            if (blockUI === true)
                unblockUi();
            if (onSuccess)
                onSuccess(response, textStatus, xhr);
        },
        error: function (xhr, ajaxOptions, thrownError) { showErrorMessage(onError, xhr, ajaxOptions, thrownError); }
    };
}

function showErrorMessage(onError, xhr, ajaxOptions, thrownError) {
    unblockUi();
    log("ERROR CALLBACK:");
    logWithHeader("xhr: ", xhr);
    logWithHeader("thrownError: ", thrownError);
    if  (xhr && xhr.responseJSON && xhr.responseJSON.message) {
        if (xhr.responseJSON.status === 401 && xhr.responseJSON.message === 'Unauthorized') {
            logout();
            // loadPage('/admin/groups?category-key=' + category.entityKey);
            // reloadPage();
            return;
        }
        showErrorModal(xhr.responseJSON.message);
    } else if (xhr && (xhr.status === 401 || xhr.status === 404)) {
        //loadPage(webAppUrl + "/login");
    } else if (thrownError && thrownError.message)
        showErrorModal(thrownError.message);
    else showErrorModal('Произошла ошибка');
    if (onError)
        onError();
}

function setXSRFHeader(xhr, settings) {
    if (settings.type === 'POST' || settings.type === 'PUT' || settings.type === 'DELETE') {
        if (!(/^http:.*/.test(settings.url) || /^https:.*/.test(settings.url))) {
            // Only send the token to relative URLs i.e. locally.
            xhr.setRequestHeader("X-XSRF-TOKEN", Cookies.get('XSRF-TOKEN'));
        }
    }
}