
function objectToArray(object, transformFn) {
    var array = [];
    forEachProperty(object, function (element, property) {
        if (transformFn)
            array.push(transformFn(element, property));
        else array.push(element);
    });
    return array;
}

function copyObject(object) {
    return Object.assign({}, object);
}

export function mergeObjects(object1, object2) {
    return Object.assign(object1, object2);
}

function isEmptyObject(object) {
    return $.isEmptyObject(object);
}

function getOwnProperties(object, properies) {
    if (isNullOrEmpty(properies) || properies.length === 0)
        return null;
    return properies.map(function (property) {
        return object[property];
    });
}

function hasOwnProperties(object, properties) {
    if (isNullOrEmpty(properties) || properties.length === 0)
        return true;
    for (var i = 0; i < properties.length; i++) {
        if (!hasOwnProperty(object, properties[i]))
            return false;
    }
    return true;
}

function hasOwnProperty(object, property) {
    return {}.hasOwnProperty.call(object, property);
}

function getInsertAfterElement(object, getElementNameFn, name) {
    var elements = objectToArray(object);
    var names = [];
    var map = {};
    elements.forEach(function (element) {
        var elementName = getElementNameFn(element);
        names.push(elementName);
        map[elementName] = element;
    });
    names.push(name);
    names.sort();
    var index = names.indexOf(name);
    var prevName = index <= 0 ? null : names[index - 1];
    return prevName ? map[prevName] : null;
}

function forEachProperty(object, fn) {
    for (var property in object) {
        if (hasOwnProperty(object, property)) {
            fn(object[property], property);
        }
    }
}