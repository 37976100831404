var REPORTS = {
    FORMAT: {
        HTML: 'html',
        PDF: 'pdf',
        EXCEL: 'xls'
    }
};

export var fieldTypes = {
    amountField: 0,
    quantityField: 1,
    digitsField: 2,
    phoneField: 3,
    numberRange: 4
};

var APPOINTMENT = {
    STATUS: {
        TRANSFER: {
            ordinal: -3,
            // backgroundColor: '#94c9d6',
            // borderColor: '#81a7b4',
            // backgroundColor: '#9e9e9e',
            backgroundColor: '#cdcdcd',
            borderColor: '#616161',
            text: 'Перенос',
            fullText: 'Перенос записи',
            iconClass: 'exchange alternate',
            btnColorClass: 'teal'
        },
        PLANNING: {
            ordinal: -2,
            backgroundColor: '#607D8B',
            borderColor: '#37474F',
            text: 'Планирование',
            fullText: 'Планирование визита',
            iconClass: 'calendar plus outline',
            btnColorClass: 'teal'
        },
        UNDEFINED: {
            ordinal: -1,
            backgroundColor: '#607D8B',
            borderColor: '#37474F',
            text: 'Неопределен',
            fullText: 'Неопределен',
            iconClass: 'plus circle',
            btnColorClass: 'teal'
        },
        WAITING_FOR_CLIENT: {
            ordinal: 0,
            backgroundColor: '#4183C4',
            borderColor: '#3a87ad',
            text: 'Ожидание',
            fullText: 'Ожидание клиента',
            iconClass: 'hourglass start',
            btnColorClass: 'blue'
        },
        CONFIRMED_BY_CLIENT: {
            ordinal: 1,
            backgroundColor: '#F9A825',
            borderColor: '#F57F17',
            text: 'Подтвержден',
            fullText: 'Визит подтвержден',
            iconClass: 'clock outline',
            btnColorClass: 'orange'
        },
        TOOK_PLACE: {
            ordinal: 2,
            backgroundColor: '#43A047',
            borderColor: '#004D40',
            text: 'Состоялся',
            fullText: 'Визит состоялся',
            iconClass: 'smile outline',
            btnColorClass: 'green'
        },
        CLIENT_DID_NOT_COME: {
            ordinal: 3,
            backgroundColor: '#ad3f43',
            borderColor: '#632b2e',
            text: 'Не состоялся',
            fullText: 'Визит не состоялся',
            iconClass: 'frown outline',
            btnColorClass: 'light-red'
        },
        CANCELLED_BY_CLIENT: {
            ordinal: 4,
            backgroundColor: '#ad3f43',
            borderColor: '#632b2e',
            text: 'Отменен',
            fullText: 'Визит отменен клиентом',
            iconClass: 'frown outline',
            btnColorClass: 'light-red'
        },
        // PAID_DIVERGES: {
        //     ordinal: 5,
        //     backgroundColor: '#7B1FA2',
        //     borderColor: '#4A148C',
        //     text: 'Оплачен',
        //     fullText: 'Оплачен',
        //     iconClass: 'handshake outline',
        //     btnColorClass: 'teal'
        // },
        DELETED: {
            ordinal: 5
        },
        PAID: {
            ordinal: 6,
            backgroundColor: '#00897B',
            borderColor: '#004D40',
            text: 'Оплачен',
            fullText: 'Визит оплачен',
            iconClass: 'handshake outline',
            btnColorClass: 'teal'
        }
    },
    SOURCE: {
        WEB_SALON_ADMIN: {
            ordinal: 0,
            text: "Веб-сайт"
        },
        TELEGRAM_CLIENT_BOT: {
            ordinal: 1,
            text: "Бот клиента"
        },
        TELEGRAM_MASTER_BOT: {
            ordinal: 2,
            text: "Бот мастера"
        },
        TELEGRAM_ADMIN_BOT: {
            ordinal: 3,
            text: "Бот администратора"
        },
        WHATSAPP_CLIENT_BOT: {
            ordinal: 4,
            text: "WhatsApp бот клиента"
        },
        WEB_CLIENT_BOT: {
            ordinal: 5,
            text: "Бот клиента"
        }
    }
};

function getStatusByOrdinal(ordinal) {
    switch (ordinal) {
        case APPOINTMENT.STATUS.WAITING_FOR_CLIENT.ordinal:
            return APPOINTMENT.STATUS.WAITING_FOR_CLIENT;
        case APPOINTMENT.STATUS.CONFIRMED_BY_CLIENT.ordinal:
            return APPOINTMENT.STATUS.CONFIRMED_BY_CLIENT;
        case APPOINTMENT.STATUS.TOOK_PLACE.ordinal:
            return APPOINTMENT.STATUS.TOOK_PLACE;
        case APPOINTMENT.STATUS.CLIENT_DID_NOT_COME.ordinal:
            return APPOINTMENT.STATUS.CLIENT_DID_NOT_COME;
        case APPOINTMENT.STATUS.DELETED.ordinal:
            return APPOINTMENT.STATUS.DELETED;
        case APPOINTMENT.STATUS.PAID.ordinal:
            return APPOINTMENT.STATUS.PAID;
        case APPOINTMENT.STATUS.PLANNING.ordinal:
            return APPOINTMENT.STATUS.PLANNING;
        case APPOINTMENT.STATUS.TRANSFER.ordinal:
            return APPOINTMENT.STATUS.TRANSFER;
        default:
            return APPOINTMENT.STATUS.UNDEFINED;
    }
}

function getSourceByOrdinal(ordinal) {
    switch (ordinal) {
        case APPOINTMENT.SOURCE.WEB_SALON_ADMIN.ordinal:
            return APPOINTMENT.SOURCE.WEB_SALON_ADMIN;
        case APPOINTMENT.SOURCE.TELEGRAM_CLIENT_BOT.ordinal:
            return APPOINTMENT.SOURCE.TELEGRAM_CLIENT_BOT;
        case APPOINTMENT.SOURCE.TELEGRAM_MASTER_BOT.ordinal:
            return APPOINTMENT.SOURCE.TELEGRAM_MASTER_BOT;
        case APPOINTMENT.SOURCE.TELEGRAM_ADMIN_BOT.ordinal:
            return APPOINTMENT.SOURCE.TELEGRAM_ADMIN_BOT;
        case APPOINTMENT.SOURCE.WHATSAPP_CLIENT_BOT.ordinal:
            return APPOINTMENT.SOURCE.WHATSAPP_CLIENT_BOT;
        case APPOINTMENT.SOURCE.WEB_CLIENT_BOT.ordinal:
            return APPOINTMENT.SOURCE.WEB_CLIENT_BOT;
    }
    return null;
}


var UPDATE_MESSAGE_TYPE = {
    MESSAGE: "MESSAGE",
    UPDATE_CALL: "UPDATE_CALL",
    NEW_APPOINTMENT: "NEW_APPOINTMENT",
    UPDATE_APPOINTMENT: "UPDATE_APPOINTMENT",
    DELETE_APPOINTMENT: "DELETE_APPOINTMENT",
    UPDATE_CLIENT_ABONEMENTS: "UPDATE_CLIENT_ABONEMENTS",
    UPDATE_CLIENT_CERTIFICATES: "UPDATE_CLIENT_CERTIFICATES",
    // CHATS
    NEW_CHAT_MESSAGE: "NEW_CHAT_MESSAGE"
};

var CHAT_TYPES = {
    ADMIN_CHAT: "ADMIN_CHAT",
    MASTER_CHAT: "MASTER_CHAT",
    CLIENT_CHAT: "CLIENT_CHAT",
    TIMEBOT_FATHER_CHAT: "TIMEBOT_FATHER_CHAT"
};

var TEXTS = {
    APPOINTMENT_CREATED: "Добавлена новая запись",
    APPOINTMENT_UPDATED: "Запись к мастеру обновлена",
    APPOINTMENT_TIME_UPDATED: "Запись успешно перенесена",
    APPOINTMENT_STATUS_UPDATED: "Статус записи обновлен",
    APPOINTMENT_DELETED: "Запись к мастеру удалена",
    ABONEMENT_SESSIONS_SAVED: "Запланированные сеансы сохранены"
};

var ABONEMENT = {
    STATUS: {
        OPEN: {
            ordinal: 0,
            name: 'OPEN'
        },
        PLANNED: {
            ordinal: 1,
            name: 'PLANNED'
        },
        TOOK_PLACE: {
            ordinal: 2,
            name: 'TOOK_PLACE'
        }
    }
};