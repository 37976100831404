var SIDEBAR = {
    TRANSITION: {
        UNCOVER: "uncover",
        OVERLAY: "overlay"
    }
};

///////////////////////////////////////////////////////////////////////////
// SIDEBAR
///////////////////////////////////////////////////////////////////////////

export function Sidebar(sidebar) {
    this.sidebar = sidebar;
}

Sidebar.prototype.setTransition = function (transition) {
    this.sidebar.sidebar('setting', 'transition', transition);
};

Sidebar.prototype.setToggler = function (toggler) {
    this.sidebar.sidebar('attach events', toggler);
};

Sidebar.prototype.show = function () {
    this.sidebar.sidebar('show');
};

Sidebar.prototype.hide = function () {
    this.sidebar.sidebar('hide');
};

Sidebar.prototype.toggle = function () {
    this.sidebar.sidebar('toggle');
};

Sidebar.prototype.isVisible = function () {
    return this.sidebar.sidebar('is visible');
};

Sidebar.prototype.isHidden = function () {
    return this.sidebar.sidebar('is hidden');
};

