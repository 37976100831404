import $ from 'jquery';
///////////////////////////////////////////////////////////////////////////
// SUBSCRIPTION CARD
///////////////////////////////////////////////////////////////////////////

import {SubscriptionCalculator} from "./subscription-calculator";
import {
    addOrRemoveClass,
    formatAmount,
    getNounDeclensionText,
    isNullOrEmpty,
    setVisibility,
    valIfOrEmpty
} from "./utils/utilities";

export function SubscriptionCard (isLanding, subscription, row, freeSms, generatePaymentButton, onPaymentBtnClicked) {
    this.isLanding = !!isLanding;
    this.row = row;
    this.generatePaymentButton = generatePaymentButton;
    this.onPaymentBtnClicked = onPaymentBtnClicked;
    this.calculator = new SubscriptionCalculator(subscription, freeSms);
    this.createCard();
    this.bindCardUI();
    if (generatePaymentButton)
        this.initPaymentButton();
}

SubscriptionCard.prototype.createCard = function () {
    var paymentButtonText = !this.generatePaymentButton ? 'Попробовать' : this.calculator.isSalonSubscription ? 'ПРОДЛИТЬ' : 'ПОДКЛЮЧИТЬ';
    var monthTooltip = valIfOrEmpty(!isNullOrEmpty(this.calculator.PERIOD.MONTH.discountText), ' data-tooltip="' + this.calculator.PERIOD.MONTH.discountText + '"');
    var quarterTooltip = valIfOrEmpty(!isNullOrEmpty(this.calculator.PERIOD.QUARTER.discountText), ' data-tooltip="' + this.calculator.PERIOD.QUARTER.discountText + '"');
    var halfYearTooltip = valIfOrEmpty(!isNullOrEmpty(this.calculator.PERIOD.HALF_YEAR.discountText), ' data-tooltip="' + this.calculator.PERIOD.HALF_YEAR.discountText + '"');
    var yearTooltip = valIfOrEmpty(!isNullOrEmpty(this.calculator.PERIOD.YEAR.discountText), ' data-tooltip="' + this.calculator.PERIOD.YEAR.discountText + '"');
    if (this.isLanding) {
        this.column = $(
            '         <div class="column">\n' +
            '            <div class="ui fluid raised link prices card">\n' +
            '                <div class="content">\n' +
            '                    <div class="header">' + this.calculator.name + '</div>\n' +
            '                    <div class="ui three mini period buttons">\n' +
            '                        <button class="ui button"' + monthTooltip + '>Месяц</button>\n' +
            '                        <button class="ui button"' + quarterTooltip + '>Квартал</button>\n' +
            '                        <button class="ui button"' + halfYearTooltip + '>Полгода</button>\n' +
            '                        <button class="ui button"' + yearTooltip + '>Год</button>\n' +
            '                    </div>\n' +
            '                </div>\n' +
            '                <div class="extra content discount-content">\n' +
            '                    <div class="ui red ribbon discount label"><i class="gift icon"></i> <span class="discount-text"></span></div>\n' +
            '                </div>' +
            '                <div class="extra content masters-count-content">\n' +
            '                    <div class="ui tiny masters statistics">\n' +
            '                        <button class="circular ui icon button decrease-master-count"><i class="minus icon"></i></button>\n' +
            '                        <div class="statistic">\n' +
            '                            <div class="value">' + this.calculator.mastersCount + '</div>\n' +
            '                            <div class="label">' + getNounDeclensionText(this.calculator.mastersCount, ['мастер', 'мастера', 'мастеров']) + '</div>\n' +
            '                        </div>\n' +
            '                        <button class="circular ui icon button increase-master-count"><i class="plus icon"></i></button>\n' +
            '                    </div>\n' +
            '                </div>' +
            '                <div class="extra content pack-list-content">\n' +
            '                    <div class="ui list">\n' +
            '                        <div class="item masters-count-text">\n' +
            '                            <i class="check icon"></i>\n' +
            '                            <div class="content">' + this.calculator.mastersCount + ' ' + getNounDeclensionText(this.calculator.mastersCount, ['мастер', 'мастера', 'мастеров']) + '</div>\n' +
            '                        </div>\n' +
            '                        <div class="item">\n' +
            '                            <i class="check icon"></i>\n' +
            '                            <div class="content">Круглосуточный доступ</div>\n' +
            '                        </div>\n' +
            '                        <div class="item">\n' +
            '                            <i class="check icon"></i>\n' +
            '                            <div class="content">Поддержка 24/7</div>\n' +
            '                        </div>\n' +
            '                    </div>\n' +
            '                </div>' +
            '                <div class="extra content prices-content">\n' +
            // '                    <div class="ui red ribbon discount label"><i class="gift icon"></i> <span class="discount-text">' + this.discountText + '</span></div>\n' +
            // '                    <div class="ui tiny masters statistics">\n' +
            // '                       <button class="circular ui icon button decrease-master-count"><i class="minus icon"></i></button>\n' +
            // '                           <div class="statistic">\n' +
            // '                               <div class="value">' + this.mastersCount + '</div>\n' +
            // '                               <div class="label">' + getNounDeclensionText(this.mastersCount, ['мастер', 'мастера', 'мастеров']) + '</div>\n' +
            // '                           </div>\n' +
            // '                       <button class="circular ui icon button increase-master-count"><i class="plus icon"></i></button>' +
            // '                    </div>\n' +
            '                    <div class="ui tiny prices statistics">\n' +
            '                        <div class="old statistic">\n' +
            '                            <div class="value"><span class="price">2 000</span> <span class="currency">₸</span></div>\n' +
            '                            <div class="label">месяц</div>\n' +
            '                        </div>\n' +
            '                        <div class="new statistic">\n' +
            '                            <div class="value"><span class="price">1 000</span> <span class="currency">₸</span></div>\n' +
            '                            <div class="label">месяц</div>\n' +
            '                        </div>\n' +
            '                    </div>\n' +
            '                </div>\n' +
            '                <div class="extra content try-button">\n' +
            '                    <div class="ui try-beauty-bot-btn show-application-modal pay-btn">' + paymentButtonText + '</div>\n' +
            '                </div>\n' +
            '            </div>\n' +
            '        </div>'
        );
    } else {
        this.column = $(
            '         <div class="column">\n' +
            '            <div class="ui fluid raised link prices card">\n' +
            '                <div class="content">\n' +
            '                    <div class="header">' + this.calculator.name + '</div>\n' +
            '                    <div class="ui three mini period buttons">\n' +
            '                        <button class="ui button"' + monthTooltip + '>Месяц</button>\n' +
            '                        <button class="ui button"' + quarterTooltip + '>Квартал</button>\n' +
            '                        <button class="ui button"' + halfYearTooltip + '>Полгода</button>\n' +
            '                        <button class="ui button"' + yearTooltip + '>Год</button>\n' +
            '                    </div>\n' +
            '                </div>\n' +
            '                <div class="extra content">\n' +
            '                    <div class="ui red ribbon discount label"><i class="gift icon"></i> <span class="discount-text"></span></div>\n' +
            '                    <div class="ui tiny masters statistics">\n' +
            '                       <button class="circular ui icon button decrease-master-count"><i class="minus icon"></i></button>\n' +
            '                           <div class="statistic">\n' +
            '                               <div class="value">' + this.calculator.mastersCount + '</div>\n' +
            '                               <div class="label">' + getNounDeclensionText(this.calculator.mastersCount, ['мастер', 'мастера', 'мастеров']) + '</div>\n' +
            '                           </div>\n' +
            '                       <button class="circular ui icon button increase-master-count"><i class="plus icon"></i></button>' +
            '                    </div>\n' +
            '                    <div class="ui tiny prices statistics">\n' +
            '                        <div class="old statistic">\n' +
            '                            <div class="value"><span class="price">2 000</span> <span class="currency">₸</span></div>\n' +
            '                            <div class="label">месяц</div>\n' +
            '                        </div>\n' +
            '                        <div class="new statistic">\n' +
            '                            <div class="value"><span class="price">1 000</span> <span class="currency">₸</span></div>\n' +
            '                            <div class="label">месяц</div>\n' +
            '                        </div>\n' +
            '                    </div>\n' +
            '                </div>\n' +
            '                <div class="extra content try-button">\n' +
            '                    <div class="ui tb-primary button show-application-modal pay-btn">' + paymentButtonText + '</div>\n' +
            '                </div>\n' +
            '            </div>\n' +
            '        </div>'
        );
    }
    this.row.append(this.column);
};

SubscriptionCard.prototype.bindCardUI = function () {
    var that = this;
    this.buttons = this.column.find('.period.buttons .button');
    this.discountLabel = this.column.find('.discount.label');
    this.discountSpan = this.column.find('.discount.label span.discount-text');
    this.oldPriceStatistic = this.column.find('.prices.statistics .old.statistic');
    this.oldPriceValue = this.oldPriceStatistic.find('.value span.price');
    this.oldPriceLabel = this.oldPriceStatistic.find('.label');
    this.priceStatistic = this.column.find('.prices.statistics .new.statistic');
    this.priceValue = this.priceStatistic.find('.value span.price');
    this.priceLabel = this.priceStatistic.find('.label');
    // Masters
    if (this.isLanding) {
        if (this.calculator.fixedMastersCount())
            setVisibility(this.column.find('.masters.statistics'), false);
        else setVisibility(this.column.find('.masters-count-text'), false);
    }
    this.mastersValue = this.column.find('.masters.statistics .value');
    this.mastersLabel = this.column.find('.masters.statistics .label');
    // Decrease count button
    this.decreaseCountBtn = this.column.find('.decrease-master-count');
    if (!this.calculator.fixedMastersCount())
        this.decreaseCountBtn.on('click', function () { that.changeMastersCount(-1); });
    else setVisibility(this.decreaseCountBtn, false);
    // Increase count button
    this.increaseCountBtn = this.column.find('.increase-master-count');
    if (!this.calculator.fixedMastersCount())
        this.increaseCountBtn.on('click', function () { that.changeMastersCount(1); });
    else setVisibility(this.increaseCountBtn, false);
    // Month
    var periodInitialized = false;
    this.monthBtn = $(this.buttons[0]);
    setVisibility(this.monthBtn, this.calculator.monthAllowed);
    if (this.calculator.monthAllowed) {
        this.monthBtn.on('click', function () { that.onPeriodSelected(that.calculator.PERIOD.MONTH, that.monthBtn); });
        this.onPeriodSelected(this.calculator.PERIOD.MONTH, this.monthBtn);
        periodInitialized = true;
    }
    // Quarter
    this.quarterBtn = $(this.buttons[1]);
    setVisibility(this.quarterBtn, this.calculator.quarterAllowed);
    if (this.calculator.quarterAllowed) {
        this.quarterBtn.on('click', function () { that.onPeriodSelected(that.calculator.PERIOD.QUARTER, that.quarterBtn); });
        if (!periodInitialized) {
            this.onPeriodSelected(this.calculator.PERIOD.QUARTER, this.quarterBtn);
            periodInitialized = true;
        }
    }
    // Half year
    this.halfYearBtn = $(this.buttons[2]);
    setVisibility(this.halfYearBtn, this.calculator.halfYearAllowed);
    if (this.calculator.halfYearAllowed) {
        this.halfYearBtn.on('click', function () { that.onPeriodSelected(that.calculator.PERIOD.HALF_YEAR, that.halfYearBtn); });
        if (!periodInitialized) {
            this.onPeriodSelected(this.calculator.PERIOD.HALF_YEAR, this.halfYearBtn);
            periodInitialized = true;
        }
    }
    // Year
    this.yearBtn = $(this.buttons[3]);
    setVisibility(this.yearBtn, this.calculator.yearAllowed);
    if (this.calculator.yearAllowed) {
        this.yearBtn.on('click', function () { that.onPeriodSelected(that.calculator.PERIOD.YEAR, that.yearBtn); });
        if (!periodInitialized)
            this.onPeriodSelected(this.calculator.PERIOD.YEAR, this.yearBtn);
    }
};

SubscriptionCard.prototype.initPaymentButton = function () {
    var that = this;
    this.payBtn = this.column.find('.pay-btn');
    this.payBtn.on('click', function () {
        if (that.onPaymentBtnClicked)
            that.onPaymentBtnClicked(that.calculator);
        else that.openPaymentPage();
    })
};

///////////////////////////////////////////////////////////////////////////
// BEHAVIOUR
///////////////////////////////////////////////////////////////////////////

SubscriptionCard.prototype.onPeriodSelected = function (period, periodBtn) {
    this.calculator.period = period;
    this.periodBtn = periodBtn;
    this.buttons.removeClass('active');
    periodBtn.addClass('active');
    this.calculator.calcMonthPrices();
    this.oldPrice = this.calculator.getOldPrice();
    this.oldPriceValue.html(formatAmount(this.oldPrice));
    this.oldPriceLabel.html(period.label);
    this.price = this.calculator.getNewPrice();
    this.priceValue.html(formatAmount(this.price));
    this.priceLabel.html(period.label);
    this.discountSpan.html(period.discountText);
    var samePrice = this.oldPrice === this.price;
    setVisibility(this.oldPriceStatistic, !samePrice);
    setVisibility(this.discountLabel, !samePrice || !isNullOrEmpty(this.discountSpan.html()), true);
};

SubscriptionCard.prototype.changeMastersCount = function (delta) {
    this.calculator.mastersCount += delta;
    addOrRemoveClass(this.decreaseCountBtn, 'disabled', this.calculator.isMinMastersCount());
    addOrRemoveClass(this.increaseCountBtn, 'disabled', this.calculator.isMaxMastersCount());
    this.mastersValue.html(this.calculator.mastersCount);
    this.mastersLabel.html(getNounDeclensionText(this.calculator.mastersCount, ['мастер', 'мастера', 'мастеров']));
    this.calculator.calcMonthPrices();
    this.onPeriodSelected(this.calculator.period, this.periodBtn);
};

SubscriptionCard.prototype.openPaymentPage = function () {
    var that = this;
    var paymentOrder = {
        amount: this.calculator.getNewPrice(),
        subscriptionId: this.calculator.id,
        salonSubscriptionId: this.calculator.salonSubscriptionId,
        mastersCount: this.calculator.mastersCount,
        monthsCount: this.calculator.period.months
    };
    ajaxPostJSON(API.SALONS.PAYMENTS.GENERATE_SUBSCRIPTION_PAYMENT_URL, paymentOrder, true, true, function (initPaymentResponse) {
        logWithHeader('GENERATE PAYMENT URL RESPONSE:', initPaymentResponse);
        if (initPaymentResponse.status === "ok" && !isNullOrEmpty(initPaymentResponse.redirectUrl))
            loadPage(initPaymentResponse.redirectUrl);
    })
};