import $ from 'jquery';
import parsePhoneNumber from 'libphonenumber-js'

///////////////////////////////////////////////////////////////////////////
// AJAX SETUP (XSRF)
///////////////////////////////////////////////////////////////////////////

// $.ajaxSetup({
//     beforeSend : function(xhr, settings) {
//         // blockUi();
//         if (settings.type === 'POST' || settings.type === 'PUT' || settings.type === 'DELETE') {
//             blockUi();
//             if (!(/^http:.*/.test(settings.url) || /^https:.*/
//                     .test(settings.url))) {
//                 // Only send the token to relative URLs i.e. locally.
//                 xhr.setRequestHeader("X-XSRF-TOKEN",
//                     Cookies.get('XSRF-TOKEN'));
//             }
//         }
//     }
// });

// $.postJSON = function(url, data, callback, errorCallback) {
//     return $.ajax(url, {
//         type: 'POST',
//         contentType : 'application/json; charset=utf-8',
//         dataType : 'json',
//         data: JSON.stringify(data),
//         success: callback,
//         error: function (xhr, ajaxOptions, thrownError) {
//             unblockUi();
//             console.log('xhr: ');
//             showErrorModal(xhr.responseJSON.message);
//             if (errorCallback)
//                 errorCallback();
//         }
//     });
// };

// $.ajaxPost = function(url, data, callback, errorCallback) {
//     return $.ajax(url, {
//         type: 'POST',
//         data: data,
//         success: callback,
//         error: function (xhr, ajaxOptions, thrownError) {
//             unblockUi();
//             console.log('xhr: ');
//             showErrorModal(xhr.responseJSON.message);
//             if (errorCallback)
//                 errorCallback();
//         }
//     });
// };

// $.ajaxGet = function (url, data, callback, errorCallback) {
//     return $.ajax(url, {
//         type: 'GET',
//         data: data,
//         success: callback,
//         error: function (xhr, ajaxOptions, thrownError) {
//             unblockUi();
//             console.log('xhr: ');
//             showErrorModal(xhr.responseJSON.message);
//             if (errorCallback)
//                 errorCallback();
//         }
//     })
// };

// $.ajaxSyncGet = function (url, data, callback, errorCallback) {
//     return $.ajax(url, {
//         type: 'GET',
//         data: data,
//         async: false,
//         success: callback,
//         error: function (xhr, ajaxOptions, thrownError) {
//             unblockUi();
//             console.log('xhr: ');
//             showErrorModal(xhr.responseJSON.message);
//             if (errorCallback)
//                 errorCallback();
//         }
//     })
// };

import {COUNTRY_CODE, LOG_ENABLED} from "../settings";
import {isArray} from "./array-utils";

export function blockUi(message) {
    var htmlMessage = '<h5 class="ui active slow large text loader">' + (message ? message : 'Пожалуйста, подождите') + '</h5>';
    $.blockUI({
        message: htmlMessage,
        css: {
            border: 'none',
            padding: '15px',
            backgroundColor: '#000',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            opacity: .5,
            color: '#fff',
            height: '120px'
        }
    });
}

export function unblockUi() {
    $.unblockUI();
}

$.fn.exists = function () {
    return this.length !== 0;
};


function showMessage(title, content) {
    $.alert({
        title: title,
        content: content,
        columnClass: 'medium'
    });
}

function showUiMessage(uiMessage, text, duration) {
    if (text) uiMessage.html(text);
    duration = valOrDefault(duration, 3000);
    if (uiMessage.exists()) {
        uiMessage.removeClass('hidden');
        setTimeout(function () {
            uiMessage.addClass('hidden');
        }, duration)
    }
}


export function showToast(text, textHeaded, bgColor, duration, icon) {
    if (isNullOrEmpty(duration))
        duration = 3;
    if (isNullOrEmpty(icon))
        icon = "check";
    $.uiAlert({
        textHead: textHeaded ? textHeaded : '',
        text: text,
        bgcolor: isNullOrEmpty(bgColor) ? '#187a62' : bgColor,
        textcolor: '#fff',
        position: 'top-right', // top And bottom ||  left / center / right
        icon: icon,
        time: duration
        // time: 3000
    });
}

function showErrorToast(text, textHeader, bgColor, duration) {
    if (isNullOrEmpty(duration))
        duration = 5;
    $.uiAlert({
        textHead: textHeader ? textHeader : '',
        text: text,
        bgcolor: isNullOrEmpty(bgColor) ? '#8c0922' : bgColor,
        textcolor: '#fff',
        position: 'top-right', // top And bottom ||  left / center / right
        icon: 'times',
        time: duration
    });
}

function showWarnToast(text, textHeader, bgColor, duration) {
    if (isNullOrEmpty(duration))
        duration = 5;
    $.uiAlert({
        textHead: textHeader ? textHeader : '',
        text: text,
        bgcolor: isNullOrEmpty(bgColor) ? '#FFA000' : bgColor,
        textcolor: '#fff',
        position: 'top-right', // top And bottom ||  left / center / right
        icon: 'info',
        time: duration
    });
}

function showInfoToast(text, textHeader, bgColor, duration) {
    if (isNullOrEmpty(duration))
        duration = 5;
    $.uiAlert({
        textHead: textHeader ? textHeader : '',
        text: text,
        bgcolor: isNullOrEmpty(bgColor) ? '#187a62' : bgColor,
        textcolor: '#fff',
        position: 'top-right', // top And bottom ||  left / center / right
        icon: 'info',
        time: duration
    });
}

function boolOrDefault(value, defaultValue) {
    return value === true || value === false ? value : defaultValue;
}

function valOrEmpty(value) {
    return valOrDefault(value, '');
}

function valOrDefault(value, defaultValue) {
    return value === null || value === undefined ? defaultValue : value;
}

function valIf(bool, val, def) {
    return bool ? val : def;
}

export function valIfOrEmpty(bool, val) {
    return valIf(bool, val, '');
}

function parseJson(json, defaultValue) {
    if (json && json !== '')
        return JSON.parse(json);
    return defaultValue;
}

function escapeCurlyBraces(string) {
    var res = string.replace("{", "\\{");
    return res.replace("}", "\\}");
}

// function hours(duration) {
//     return Math.floor(duration / 60);
// }
//
// function minutes(duration) {
//     return Number(duration) % 60;
// }

// function calcDuration(hours, minutes) {
//     return toNumber(hours) * 60 + toNumber(minutes)
// }

function toNumber(value) {
    return isNumber(value) ? value : Number(value);
}

export function isNumber(value) {
    return typeof value === 'number';
}

export function parseIntOrZero(string) {
    if (isNumber(string))
        return string;
    return string && string.length !== 0 ? parseInt(string.replace(/ /g,'')) : 0;
}

export function parseFloatOrZero(string) {
    if (isNumber(string))
        return string * 1.0;
    return string && string.length !== 0 ? parseFloat(string.replace(/ /g,'')) : 0.0;
}

export function isNullOrEmpty(value) {

    return value == null || value === undefined || value === '' || (isArray(value) && value.length === 0)
}

function isNullOrZero(value) {
    return value == null || value === 0 || value === 0.0;
}



function getIntWithNounDeclensionText(value, nounDeclensions, emptyZero) {
    if (emptyZero == null || emptyZero === undefined)
        emptyZero = true;
    var text = "";
    if (value === 0)
        return emptyZero === true ? text : '0 ' + nounDeclensions[2];
    var number = value % 100;
    if (number >= 11 && number <= 19)
        return value + ' ' + nounDeclensions[2];
    number %= 10;
    switch (number) {
        case 1:
            return value + ' ' + nounDeclensions[0];
        case 2:
        case 3:
        case 4:
            return value + ' ' + nounDeclensions[1];
        default:
            return value + ' ' + nounDeclensions[2];
    }
}

export function getNounDeclensionText(value, nounDeclensions, emptyZero) {
    if (emptyZero == null || emptyZero === undefined)
        emptyZero = true;
    var text = "";
    if (value === 0)
        return emptyZero === true ? text : nounDeclensions[2];
    var number = value % 100;
    if (number >= 11 && number <= 19)
        return nounDeclensions[2];
    number %= 10;
    switch (number) {
        case 1:
            return nounDeclensions[0];
        case 2:
        case 3:
        case 4:
            return nounDeclensions[1];
        default:
            return nounDeclensions[2];
    }
}

function emptyIfNull(value) {
    return value == null || value === undefined ? '' : value;
}

export function setVisibility(el, visible, useInvisibleClass) {
    if (visible)
        show(el, useInvisibleClass);
    else hide(el, useInvisibleClass);
}

function show(el, useInvisibleClass) {
    if (isArray(el))
        el.forEach(function (item) { showElement(item, useInvisibleClass); });
    else showElement(el, useInvisibleClass);
}

function hide(el, useInvisibleClass) {
    if (isArray(el))
        el.forEach(function (item) { hideElement(item, useInvisibleClass); });
    else hideElement(el, useInvisibleClass);
}

function showElement(el, useInvisibleClass) {
    removeElementClass(el, useInvisibleClass ? 'invisible' : 'hidden');
}

function hideElement(el, useInvisibleClass) {
    addElementClass(el, useInvisibleClass ? 'invisible' : 'hidden');
}

export function addOrRemoveClass(el, clazz, add) {
    if (el) {
        if (add === true)
            addClass(el, clazz);
        else removeClass(el, clazz);
    }
}

function addClass(el, clazz) {
    if (el && isArray(el))
        el.forEach(function (item) { addElementClass(item, clazz); });
    else addElementClass(el, clazz);
}

function removeClass(el, clazz) {
    if (el && isArray(el))
        el.forEach(function (item) { removeElementClass(item, clazz); });
    else removeElementClass(el, clazz);
}

function addElementClass(el, clazz) {
    if (el && el.exists() && !isNullOrEmpty(clazz) && !el.hasClass(clazz))
        el.addClass(clazz);
}

function removeElementClass(el, clazz) {
    if (el && el.exists() && !isNullOrEmpty(clazz) && el.hasClass(clazz))
        el.removeClass(clazz);
}

function reloadPage() {
    location.reload();
}

function loadPage(url) {
    $(location).attr('href', encodeURI(url));
}

function loadPageInNewTab(url) {
    window.open(encodeURI(url), '_blank');
}



export function hasAttribute(el, name) {
    if (!el.exists()) return false;
    var attr = el.attr(name);
    // For some browsers, `attr` is undefined; for others, `attr` is false.  Check for both.
    return typeof attr !== typeof undefined && attr !== false;
}

export function parsePhone(value) {
    if (isNullOrEmpty(value))
        return null;
    var phoneNumber = null;
    try {
        phoneNumber = parsePhoneNumber(value, COUNTRY_CODE);
        if (!phoneNumber || !phoneNumber.isValid()) {
            log("The phone number " + value + " does not fit the default region: " + COUNTRY_CODE + ". Trying to parse international number");
            var number = value.startsWith("+") ? value : "+" + value;
            phoneNumber = parsePhoneNumber(number, COUNTRY_CODE);
        }
        // log(phoneNumber.isPossible());
        // log(phoneNumber.isValid());
        // log(phoneNumber.formatInternational());
    } catch(ex) {
        log(ex);
    }
    return phoneNumber;
}

function getNotImplementedMethodText(fnName, methodName) {
    alert('Method "' + methodName + '" of "' + fnName + '" is not implemented');
}

function concatWithSpace(first, second) {
    switch (true) {
        case !isNullOrEmpty(first) && !isNullOrEmpty(second):
            return first + ' ' + second;
        case !isNullOrEmpty(first):
            return first;
        case !isNullOrEmpty(second):
            return second;
        default:
            return '';
    }
}

function readFileToImg(input, img) {
    if (!checkInputImageFile(input))
        return false;
    readAsDataURL(input.files[0], function (result) {
        img.attr('src', result);
    });
    return true;
}

function readFileToBg(input, el) {
    if (!checkInputImageFile(input))
        return false;
    readAsDataURL(input.files[0], function (result) {
        el.css('background-image', "url(" + result + ")");
    });
    return true;
}

function readImageAsDataUrl(input, processResultFn) {
    if (!checkInputImageFile(input))
        return false;
    readAsDataURL(input.files[0], processResultFn);
    return true;
}

function readAsDataURL(file, processResultFn) {
    var reader = new FileReader();
    reader.onload = function (e) {
        if (processResultFn)
            processResultFn(e.target.result);
    };
    reader.readAsDataURL(file);
}

function checkInputImageFile(input) {
    if (!(input.files && input.files[0]))
        return false;
    var fileExtension = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];
    return $.inArray($(input).val().split('.').pop().toLowerCase(), fileExtension) !== -1;
}

function dataUrlToBlob(dataUrl) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataUrl.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataUrl.split(',')[1]);
    else byteString = unescape(dataUrl.split(',')[1]);
    // separate out the mime component
    var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
}


var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

export function getInputParameter(el) {
    if (el.exists()) {
        var value = el.val();
        el.remove();
        return value;
    }
    return null;
}

function getInstagramLink(instagramLink) {
    var httpText = "";
    if (!instagramLink.includes("http"))
        httpText = "https://";
    var domainText = "";
    if (!instagramLink.includes("instagram.com"))
        domainText = "instagram.com/";
    return httpText + domainText + instagramLink.replace("@", "");
}

function durationMillisToMinSec(millis) {
    var minutes = durationMillisToMinutes(millis);
    var seconds = durationMillisToSeconds(millis);
    return pad(minutes, 2) + ':' + pad(seconds, 2);
}

function durationMillisToShortText(millis) {
    var minutes = durationMillisToMinutes(millis);
    var seconds = durationMillisToSeconds(millis);
    return getIntWithNounDeclensionText(minutes, ['мин', 'мин', 'мин'], true) + ' ' + getIntWithNounDeclensionText(seconds, ['с', 'с', 'с'], false)
}

function durationMillisToText(millis) {
    var minutes = durationMillisToMinutes(millis);
    var seconds = durationMillisToSeconds(millis);
    return getIntWithNounDeclensionText(minutes, ['минута', 'минуты', 'минут'], true) + ' ' + getIntWithNounDeclensionText(seconds, ['секунда', 'секунды', 'секунд'], false)
}

function durationMillisToMinutes(duration) {
    return Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
}

function durationMillisToSeconds(duration) {
    return Math.floor((duration % (1000 * 60)) / 1000);
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function addStyleAttribute($element, styleAttribute) {
    var prevStyle = $element.attr('style');
    prevStyle = isNullOrEmpty(prevStyle) ? '' : prevStyle + '; ';
    $element.attr('style', prevStyle + styleAttribute);
}

function slidePage(scrollableContainer, pageWidth, slideRight, duration) {
    if (isNullOrEmpty(duration))
        duration = 600;
    var from = scrollableContainer.scrollLeft;
    var to = slideRight ? from + pageWidth : from - pageWidth;
    scrollXAxis(scrollableContainer, to, duration);
}

function scrollXAxis(element, to, duration) {
    var start = element.scrollLeft(),
        change = to - start,
        currentTime = 0,
        increment = 20;
    var animateScroll = function() {
        currentTime += increment;
        element.scrollLeft(Math.easeInOutQuad(currentTime, start, change, duration));
        if (currentTime < duration)
            setTimeout(animateScroll, increment);
    };
    animateScroll();
}

function scrollYAxis(element, to, duration) {
    var start = element.scrollTop(),
        change = to - start,
        currentTime = 0,
        increment = 20;
    var animateScroll = function() {
        currentTime += increment;
        // element.scrollTop = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop(Math.easeInOutQuad(currentTime, start, change, duration));
        if (currentTime < duration)
            setTimeout(animateScroll, increment);
    };
    animateScroll();
}

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

export function formatAmount(amount, currency, blankZero) {
    if (amount === 0.0 && blankZero === true)
        return "";
    if (isNullOrEmpty(amount)) {
        if (blankZero === true)
            return "";
        else amount = 0.0;
    }
    var currencyText = !isNullOrEmpty(currency) ? " " + currency : "";
    return amount.toLocaleString("ru-RU") + currencyText
}

function formatQuantity(quantity, blankZero) {
    if (quantity === 0 && blankZero === true)
        return "";
    if (isNullOrEmpty(quantity)) {
        if (blankZero === true)
            return "";
        else quantity = 0;
    }
    return quantity
}

export function log(any) {
    if (LOG_ENABLED)
        console.log(any);
}

var performanceStartTime;

function initDurationMeasurement() {
    performanceStartTime = performance.now();
}

function logDuration(description) {
    if (LOG_ENABLED) {
        var newStartTime = performance.now();
        var duration = newStartTime - performanceStartTime;
        console.log(description + ' took ' + duration + 'ms');
        performanceStartTime = newStartTime;
    }
}

export function logWithHeader(header, any) {
    if (LOG_ENABLED) {
        console.log(header);
        console.log(any);
    }
}

function getEngNumberName(number) {
    switch (number) {
        case 1:
            return 'one';
        case 2:
            return 'two';
        case 3:
            return 'three';
        case 4:
            return 'four';
        default:
            return '';

    }
}


function checkIsTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mediaQuery = function(query) {
        return window.matchMedia(query).matches;
    };
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }
    // Include the 'heartz' as a way to have a non matching MQ to help terminate the join https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mediaQuery(query);
}

function setInputTextChangedCallback(input, callback) {
    if (input.exists() && !isNullOrEmpty(callback)) {
        input.keyup(function (event) {
            // Prevent formatting on selection
            var selection = window.getSelection().toString();
            if (selection !== '') return;
            // Prevent formatting on arrow keys and delete key (46) and backspace (8)
            if ($.inArray(event.keyCode, [38, 40, 37, 39]) !== -1) return;
            // Trigger callback
            var newText = $(this).val();
            callback(newText);
        });
    }
}
