import {SubscriptionCard} from "./subscription-card";
import $ from 'jquery';

export function PricesGrid(isLanding, subscriptions, freeSms, generatePaymentButton, onPaymentBtnClicked) {
    //logWithHeader("SUBSCRIPTIONS: ", subscriptions);
    this.subscriptions = subscriptions;
    this.bindUI();
    this.createSubscriptionCards(isLanding, subscriptions, freeSms, generatePaymentButton, onPaymentBtnClicked);
}

PricesGrid.prototype.bindUI = function () {
    this.pricesGrid = $('#prices-grid');
};

PricesGrid.prototype.createSubscriptionCards = function (isLanding, subscriptions, freeSms, generatePaymentButton, onPaymentBtnClicked) {
    var row = $('<div class="row">');
    this.pricesGrid.append(row);
    subscriptions.forEach(function (subscription) {
        var card = new SubscriptionCard(isLanding, subscription, row, freeSms, generatePaymentButton, onPaymentBtnClicked);
    });
};