import {Modal} from "./modal";

export function ErrorModal(modal) {
    Modal.call(this, modal);
    this.header = modal.find('#error-modal-header');
    this.content = modal.find('#error-modal-text');
    this.closeButton = modal.find('.cancel.button');
}

ErrorModal.prototype = Object.create(Modal.prototype);

ErrorModal.prototype.setHeader = function (header) {
    this.header.html(header);
};

ErrorModal.prototype.setText = function (text) {
    this.content.html(text);
};

ErrorModal.prototype.setCallback = function (callback) {
    if (callback) {
        this.closeButton.off('click');
        this.closeButton.on('click', function () {
            callback();
        });
    }
};