var $ = require("jquery")

$.uiAlert = function(options) {
    var setUI = $.extend({
        textHead: 'Your user registration was successful.',
        text: 'You may now log-in with the username you have chosen',
        textcolor: '#19c3aa',
        bgcolors: '#fff',
        position: 'top-right',
        icon: '',
        time: 5,
        permanent: false
    }, options);
    return new Toast(setUI);
};

function Toast(options) {
    this.initContainer('ui-alert-content' + '-' + options.position);
    this.createToast(options);
}

Toast.prototype.initContainer = function (containerClass) {
    this.container = $('body > .' + containerClass);
    if (!this.container.length) {
        this.container = $('<div class="ui-alert-content ' + containerClass + '" style="width: inherit;">');
        $('body').append(this.container);
    }
};

Toast.prototype.createToast = function (options) {
    var that = this;
    this.time = options.time * 1000;
    var bgColor = options.bgcolor ? options.bgcolor : '';
    var toastStyles =
        'background-color: ' + bgColor + '; ' +
        'box-shadow: 0 0 0 1px rgba(255, 255, 255, .5) inset, 0 0 0 0 transparent; ' +
        'min-width: 400px; ' +
        'opacity: 0.7;';
    var toast = $(
        '<div id="messages" class="ui small icon message" style="' + toastStyles + '">' +
        '   <i class="' + options.icon + ' icon message-icon" style="color: ' + options.textcolor + ';"></i>' +
        '   <i class="close icon" style="color: ' + options.textcolor+';" id="messageclose"></i>' +
        '   <div style="color: ' + options.textcolor + '; margin-right: 10px;">' +
        '       <div class="header">' + options.textHead+'</div>' +
        '       <p> ' + options.text + '</p>' +
        '   </div>' +
        '</div>'
    );
    this.toast = toast;
    this.container.prepend(toast);
    toast.animate({ opacity: '0.9' }, 300);
    if (options.permanent === false) {
        $(toast).mouseenter(function() { clearTimeout(that.timer); }).mouseleave(function() { that.setHideTimeout(); });
        this.setHideTimeout();
    }
    toast.find('#messageclose').on('click', function() {
        toast.transition('fade');
        // $(this).closest('#messages').transition('fade');
    });
};

Toast.prototype.setHideTimeout = function () {
    var that = this;
    this.timer = setTimeout(
        function() {
            that.toast.animate({ opacity: '0'}, 300, function() { that.hide(); });
        },
        this.time
    );
};

Toast.prototype.hide = function () {
    this.toast.remove();
};